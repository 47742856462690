let slider = function ($) {

    /**
     * Run slider
     */
    let runSlider = function () {
        $('.slider .items').bxSlider({
            mode: 'fade',
            infiniteLoop: false,
            hideControlOnEnd: true,
            nextSelector: '.slider-prev',
            prevSelector: '.slider-prev',
            controls: true,
            nextText: '<i class="fas fa-chevron-right"></i>',
            prevText: '<i class="fas fa-chevron-left"></i>'
        });
        $('body').on('mousedown', 'textarea', function() {
            $(this).focus();
        });
    };

    return {
        init: function () {
            // runSlider();
        }
    };
}(jQuery);

$(document).ready(function () {
    slider.init();
});